import * as React from "react"
import { graphql } from "gatsby"

import { Box, 
  Container,
} from "@chakra-ui/react"

  import Seo from "../components/seo"
  import BackgroundImage from "../components/Backgrounds/BackgroundImage"
  import SidebarNav from "../components/SidebarNav/SidebarNav"
  import MainContentBox from "../components/MainContentBox/MainContentBox"
  import MobileNav from "../components/MobileNav/MobileNav"
  import Footer from "../components/Footer/Footer"
  import PageHero  from "../components/PageHero/PageHero"
  import SliceZone from "../components/SliceZone/SliceZone"
  import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
  import { ChevronRightIcon } from "@chakra-ui/icons"
  import { getImage } from "gatsby-plugin-image"
  import ProjectCardRack from '../components/ProjectCard/ProjectCardRack'

  const Resources = ({ pageContext, location, data, props }) => {
    const pageLocation = location.pathname
    const pageHash = location.hash
    const {
      breadcrumb: { crumbs },
    } = pageContext

    if (!data) return null
    const document = data.prismicResourcesPage.data
  
    const heroContent = {
      title: document.page_title.text,
      description: document.page_description.text,
      ctaButtons: document.cta_buttons,
      //featuredImage: document.featured_images[0]?.featured_image.localFile
    }

    const customCrumbLabel = heroContent.title
    const backgroundImage = data.prismicResourcesPage.data.background_image.localFile

    return (
      
      <>
      <Seo title="Teton Valley Advocates | Resources" />
      <BackgroundImage
        backgroundImage={getImage(backgroundImage)}
      >
        <Container maxW="container.xl">
          <MobileNav />
          <Box paddingTop="20" width="100%">
              <SidebarNav 
                pathName={pageLocation}
              />
          <MainContentBox>
             <PageHero 
              title={heroContent.title}
              description={heroContent.description}
              buttons={heroContent.ctaButtons}
              //location={location}
              //crumbLabel={heroContent.title}
              //featuredImage={heroContent.featuredImage}
            />
          <Box p="4" backgroundColor="rgba(255, 255, 255, 0.8)" textAlign="center">
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator={<ChevronRightIcon />}
              crumbLabel={customCrumbLabel}
          />
          </Box>
          <Box padding="4" backgroundColor="rgba(255, 255, 255, 0.8)">
            <ProjectCardRack />
          </Box>
          <Box>
            <SliceZone sliceZone={document.body} location={pageHash} />
          </Box>
          {/* <BlogPostsBlock title="Facebook Block..." />       */}
            <Footer />
          </MainContentBox>
          </Box>

        </Container>

    </BackgroundImage>
      </>
    )
  }

  export const query = graphql`
  query Resources {
    prismicResourcesPage {
      data {
        body {
          ... on PrismicResourcesPageDataBodyOurWorkTabs {
            id
            slice_type
            items {
              tab_id {
                text
              }
              tab_title {
                text
              }
              tab_subtitle {
                text
              }
              tab_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 60, placeholder: BLURRED, layout: FULL_WIDTH)
                  }
                }
              }
              tab_content {
                raw
              }
            }
            primary {
              tab_section_title {
                text
              }
              tab_section_subtitle {
                text
              }
            }
          }
        }
        background_image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 8, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        page_title {
          text
        }
        page_description {
          text
        }
      }
      id
      type
      uid
    }
  }
`

  export default Resources
